import SignIn from "../Pages/AuthPages/login.jsx";

// @mui icons
import Icon from "@mui/material/Icon";

const AuthRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  }
];

export default AuthRoutes;