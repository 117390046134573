import React, { useState, useEffect } from 'react';
import LoginComponent from '../../components/Auth/loginComponent.jsx';

function Login() {

  return (
    <>
      <LoginComponent />
    </>
  )
}

export default Login;