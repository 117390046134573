import axios from 'axios';
import BaseUrl from './baseUrl';
const baseUrl = BaseUrl();
// http://localhost:3001/api/product/get_local_products
export default new (class LocalProduct_Service {
    getProducts() {
        return axios.get(`${baseUrl}/product/get_local_products`)
    }
    // http://localhost:3001/api/product/final_product_table
    getAllProducts() {
        return axios.get(`${baseUrl}/product/final_product_table`)
    }

    // {{Shopify_Url}}/product/final_product_table_pagination?search=SS&_page=&_limit=10&_sort=price&_order=desc

    getAllProductsPagination(QueryData) {
        return axios.get(`${baseUrl}/product/final_product_table_pagination?search=${QueryData?.search}&_page=${QueryData?.page}&_limit=${QueryData?.limit}&_sort=${QueryData?.sort}&_order=${QueryData?.order}`)
    }

    // http://localhost:3001/api/product/update_product_data_final?id=44558666006808
    updateProduct(data, id) {
        return axios.put(`${baseUrl}/product/update_product_data_final?id=${id}`, data)
    }

    XmlFileUpload(Inventory) {
        console.log("req.file_Inventory", Inventory)
        return axios.post(`${baseUrl}/upload/xml_upload`, Inventory, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        })
    }

    addXmlData() {
        return axios.get(`${baseUrl}/xml/add_xml`)
    }

    filterXmlData() {
        return axios.post(`${baseUrl}/xml/filter_xml`)
    }

    checkQuantity() {
        return axios.get(`${baseUrl}/product/check_quantity_by_sku_db`)
    }

    get_all_sku() {
        return axios.get(`${baseUrl}/xml/get_all_sku_list`)
    }

    get_all_block_sku() {
        return axios.get(`${baseUrl}/xml/get_all_block_sku_list`)
    }

    edit_block_sku(id, sku) {
        return axios.put(`${baseUrl}/xml/edit_block_sku/${id}`, sku)
    }

    delete_block_sku(id) {
        return axios.delete(`${baseUrl}/xml/delete_block_sku/${id}`)
    }

    delete_multiple_block_sku() {
        return axios.delete(`${baseUrl}/xml/delete_multiple_block_sku`)
    }

    syncData() {
        return axios.get(`${baseUrl}/product/update_products_to_shopify`)
    }

    cron_sync_products() {
        return axios.get(`${baseUrl}/product/cron_sync_products`)
    }


    get_all_fatched_xml() {
        return axios.get(`${baseUrl}/xml/get_all_fatched_xml`)
    }


    get_last_fatched_xml() {
        return axios.get(`${baseUrl}/xml/get_last_fatched_xml`)
    }

    fetch_xml_date_time() {
        return axios.get(`${baseUrl}/xml/fetch_xml_date_time`)
    }

    stop_update_products_to_shopify() {
        return axios.get(`${baseUrl}/product/stop_update_products_to_shopify`)
    }

    add_updated_product_db() {
        return axios.post(`${baseUrl}/product/add_updated_product_db`)
    }


    stop_Analysis_FileFating_Update(data) {
        return axios.post(`${baseUrl}/product/stop_Analysis_FileFating/update`, data)
    }



    // APIs for xcel sheet data start 

    xcel_sheet_upload(Inventory) {
        return axios.post(`${baseUrl}/upload/xcel_sheet_upload`, Inventory)
    }

    vendor4_sheet_upload(Inventory) {
        return axios.post(`${baseUrl}/upload/upload_vendor4_csv`, Inventory)
    }


    add_price_xcel_sheet_data_db() {
        return axios.post(`${baseUrl}/product/add_price_xcel_sheet_data_db`)
    }

    update_vendor_4_products() {
        return axios.get(`${baseUrl}/product/update_vendor_4_products`)
    }


    check_and_addupdated_price_db() {
        return axios.post(`${baseUrl}/product/check_and_addupdated_price_db`)
    }

    // APIs for xcel sheet data End


    // Sync Products start 
    syncProductsFromShopify() {
        return axios.get(`${baseUrl}/db/new_pagination_add_product_db`)
    }


    stop_sync_process_from_shopify() {
        return axios.get(`${baseUrl}/db/stop_sync_process_from_shopify`)
    }


    // ** For Backup
    addProductsVariants() {
        return axios.get(`${baseUrl}/db/stop_sync_process_from_shopify`)
    }
    // Sync Products end

    add_tags_on_multiple_products(data) {
        return axios.put(`${baseUrl}/product/add-tags-on-multiple-products`, data)
    }



})

