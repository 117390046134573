import React, { useEffect, useMemo, useState, useCallback, useLayoutEffect } from 'react';
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from "../../components/MDButton";
import Table from '../../components/Ui/table.jsx';


// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { Box } from '@mui/material';
import MDProgress from "../../components/MDProgress";
// Import APIs 
import LocalApi from '../../Services/Local/product.local.service';
function PriceTable() {
    const [isFileFatch, setIsFileFatch] = useState(false);
    const [isFileFatchProcess, setIsFileFatchProcess] = useState(false);
    const [isSyncProductFromShopify, setIsSyncProductFromShopify] = useState(false);

    const [sheetData, setSheetData] = useState({})
    const [pageSize, setPageSize] = useState(5);
    const [rowId, setRowId] = useState(null);
    const [blockSkuList, setBlockSkuList] = useState([]);
    const [ResultArray, setResultArray] = useState([]);
    const [customDeleteProgress, setCustomDeleteProgress] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState("Sync Products");
    const [customMessage, setCustomMessage] = useState('Sync Products is under...');
    const [isDeleteLoding, setIsDeleteLoding] = useState(false);

    const [customProgress, setCustomProgress] = useState(0);
    const [data, setData] = useState([])
    // const [columns, setColumns] = useState([])
    const navigate = useNavigate()


    useLayoutEffect(() => {
        LocalApi.cron_sync_products().then((response) => {
            // console.log("cron_sync_products_response", response)
            setIsSyncProductFromShopify(response?.data?.isSyncProductFromShopify)
            setIsFileFatch(response?.data?.isFileFatch)
            setIsFileFatchProcess(response?.data?.isFileFatchProcess)
        })
    }, [])

    const handleResetProcess = async (event) => {
        setIsSyncProductFromShopify(false)
        await LocalApi.stop_sync_process_from_shopify().then((res) => {
            alert("Process Reset")
            setIsSyncProductFromShopify(false)
            setIsLoading(false)
            window.location.reload()
        }).catch((err) => {
            console.log("stop_sync_process_from_shopify_err", err)
            setIsLoading(false)
            alert("Something wrong on Cancel Process")
            window.location.reload()
        })
    }

    const handleSyncProducts = async (event) => {
        setIsLoading(true)
        setIsSyncProductFromShopify(true)
        setLoadingButton("Syncing Products...")

        let Sync_Products = await LocalApi.syncProductsFromShopify().then((res) => {
            setCustomProgress(100)
            setLoadingButton("Sync Products")
            setSheetData({})
            // console.log("handleUploadSheet_err", err)
            alert("Sync Products Process is completed")
            setIsLoading(false)
            setIsSyncProductFromShopify(false)
            window.location.reload()
        }).catch((err) => {
            setIsLoading(false)
            setIsSyncProductFromShopify(false)
            setLoadingButton("Sync Products")
            setSheetData({})
            // console.log("handleUploadSheet_err", err)
            setCustomProgress(0)
            alert("Something wrong on Sync Products Process")
            window.location.reload()
        })

        // if (Sync_Products) {
        //     setCustomProgress(50)
        //     setLoadingButton("add products...")
        //     setCustomMessage("add products on database...")
        //     await LocalApi.addProductsVariants().then((res) => {
        //         setCustomProgress(100)
        //         setLoadingButton("Sync Products")
        //         setSheetData({})
        //         // console.log("handleUploadSheet_err", err)
        //         alert("Sync Products Process is completed")
        //         setIsLoading(false)
        //         window.location.reload()
        //     }).catch((err) => {
        //         setIsLoading(false)
        //         setLoadingButton("Sync Products")
        //         setSheetData({})
        //         // console.log("handleUploadSheet_err", err)
        //         setCustomProgress(0)
        //         alert("Something wrong on adding products on database process")
        //         window.location.reload()
        //     })
        // }
    }

    return (
        <>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        {/* <Card> */}
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3 className="card-title">Sync Products from Shopify</h3>
                                    {/* <form encType="multipart/form-data" onSubmit={handleUploadSheet}> */}
                                    {/* <MDButton
                                        color="light"
                                        type="submit"
                                        variant="outlined"
                                        sx={{ mr: '20px' }}
                                        disabled={isloading}
                                        onClick={handleSyncProducts}
                                    >{loadingButton}</MDButton> */}
                                    {((!isFileFatch && isFileFatchProcess) || (isFileFatch && !isFileFatchProcess) || (isFileFatch && isFileFatchProcess) || isSyncProductFromShopify) ?
                                        <>
                                            {isSyncProductFromShopify ?
                                                <>
                                                    <>
                                                        <MDButton
                                                            color="secondary"
                                                            variant="gradient"
                                                            type="submit"
                                                            sx={{ mr: '-490px' }}
                                                            disabled={isSyncProductFromShopify}
                                                            onClick={handleSyncProducts}
                                                        >Syncing Products...</MDButton>
                                                        <MDButton
                                                            color="light"
                                                            variant="outlined"
                                                            sx={{ mr: '0px' }}
                                                            onClick={handleResetProcess}
                                                        >Cancel Process</MDButton>

                                                    </>
                                                </>
                                                :
                                                <>
                                                    <MDButton className="custom_button_class" sx={{ mx: 2 }} variant="gradient" color="light" disabled >Please wait, File is fatching process is running...</MDButton>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <MDButton
                                                color="light"
                                                type="submit"
                                                variant="outlined"
                                                sx={{ mr: '20px' }}
                                                disabled={isSyncProductFromShopify}
                                                onClick={handleSyncProducts}
                                            >{loadingButton}</MDButton>
                                        </>}
                                    {/* </form> */}
                                </Box>
                            </MDTypography>

                        </MDBox>

                        {isloading ?
                            <>
                                {/* <MDProgress value={customProgress} variant="gradient" label /> */}
                                <MDBox pt={3} pb={3} pl={2}>
                                    Please wait while {customMessage}
                                </MDBox>
                            </>
                            :
                            <>
                                <MDBox pt={3} pl={2} pb={3}>
                                    <Link to="/products">
                                        <MDButton
                                            color="secondary"
                                            variant="gradient"
                                            sx={{ mr: '20px' }}
                                        >
                                            Goto Inventory
                                        </MDButton>
                                    </Link>
                                </MDBox>
                            </>
                        }
                        {/* <MDBox pt={3}>
                                <div className="table-responsive">
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                        }}
                                    >

                                    </Box>
                                </div>
                            </MDBox> */}
                        {/* </Card> */}
                    </Grid>
                </Grid>
            </MDBox>
        </>
    )
}

export default PriceTable