import { WithContext as ReactTags } from 'react-tag-input';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import './custom.css';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from "../../components/MDButton";

// Material Dashboard 2 React components
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box } from '@mui/material';
import MDProgress from "../../components/MDProgress";


import CustomRemoveIcon from './CustomRemoveIcon';
import { TagsInput } from "@enipx/react-tags-input";


// Api Import
import LocalAPi from '../../Services/Local/product.local.service';
import axios from 'axios';
import globalBaseUrl from '../../Services/baseUrl'
const baseUrl = globalBaseUrl()





const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function Create() {
  const [tags, setTags] = useState([]);
  const [buttonName, setButtonNam] = useState('Add Bulk SKU');
  const [isBulk, setIsBulk] = useState(false);
  const [allSku, setAllSku] = useState([]);
  const [customProgress, setCustomProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [selected_1, setSelected_1] = useState([]);
  const [bulkSKU, setBulkSKU] = useState([])




  let sku_arr_list = [];
  for (let i = 0; i < allSku.length; i++) {
    sku_arr_list.push(allSku[i].sku)
  }

  const suggestions = sku_arr_list.map((country) => {
    return {
      id: country,
      text: country,
    };
  });

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const handleAddMultiTags = async () => {
    let obj = {
      SKUs: bulkSKU,
      Tags: selected_1
    }
    await LocalAPi.add_tags_on_multiple_products(obj).then((res) => {
      console.log("handleAddMultiTags_res", res)
      alert("process completed")
      setBulkSKU([])
      setSelected_1([])
      setSelected([])
    }).catch((err) => {
      console.log("handleAddMultiTags_err", err)
      alert("Something wrong!")
      window.location.reload()
    })
    // console.log("handleAddMultiTags",obj)
  }

  const handleResetProcess = (event) => {
    event.preventDefault()
    setBulkSKU([])
    setSelected_1([])
    setSelected([])

  }
  const onChangeHandler = (e) => {
    console.log("onChangeHandler", e);
    setBulkSKU(e);
  }

  const onChangeHandler_1 = (e) => {
    console.log("onChangeHandler_1", e);
    setSelected_1(e);
  }

  // console.log('tags', tags)
  return (
    <div className="app">
      <div>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {/* <Card> */}
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3 className="card-title">Add Tags on Multiple Products</h3>
                    </Box>
                    <div>
                      <MDButton sx={{ mr: '20px' }} variant="gradient" color="secondary" onClick={() => handleAddMultiTags()}>Submit</MDButton>
                      <MDButton variant="outlined" color="secondary" onClick={(e) => handleResetProcess(e)}><ArrowBackIosNewIcon />Cancel</MDButton>
                    </div>
                  </Box>
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>
              </MDBox>
              {/* </Card> */}
              <Card>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    p: '20px'
                  }}
                >
                  <>
                    {/* <from onSubmit={handleAddMultiTags}> */}
                    <TagsInput
                      value={selected}
                      onChange={onChangeHandler}
                      style={{
                        // border: `1px solid rgba(0,0,0,0.001)`,
                        alignItems: 'center',
                        textAlign: 'center',
                        minHeight: '50px',
                        borderRadius: '10px',
                        // padding: '1.5rem',
                        fontSize: '0.85rem',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        paddingLeft: '1rem',
                        paddingRight: '0.5rem'
                      }}
                      // focusStyle={{
                      //   border: `1px solid rgba(0,0,0,0.4)`,
                      // }}
                      tagStyle={{
                        // border: `1px solid`,
                        boxShadow: "0 0 5px rgba(0,0,0,0.5)",
                        padding: '0.4rem',
                        fontSize: '1rem',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: '1rem',
                        paddingLeft: '1rem',
                        paddingRight: '0.2rem',


                      }}
                      removeIcon={<CustomRemoveIcon />}
                    />
                    <Form.Group>
                      <MDBox sx={{ display: 'flex', alignItems: 'center', marginTop: "10px" }}>
                        <label htmlFor="exampleInputName1">Tags</label>
                        <MDBox sx={{ ml: "40px" }} >
                          <TagsInput
                            classNames="Custom_TagInput"
                            value={selected_1}
                            onChange={onChangeHandler_1}
                            name="TAGS"
                            placeHolder="Enter tags here"
                          />
                        </MDBox>
                      </MDBox>
                    </Form.Group>
                    {/* <MDButton sx={{ mr: '20px' }} variant="gradient" color="secondary" type="submit">Submit</MDButton>  */}
                    {/* </from> */}
                  </>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </div>
    </div >
  );
};


export default Create;