import React, { useEffect, useMemo, useState, useCallback } from 'react';
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from "../../components/MDButton";
import Table from '../../components/Ui/table.jsx';


// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { Box } from '@mui/material';
import MDProgress from "../../components/MDProgress";
// Import APIs 
import LocalApi from '../../Services/Local/product.local.service';
function PriceTable() {
    const [sheetData, setSheetData] = useState({})
    const [pageSize, setPageSize] = useState(5);
    const [rowId, setRowId] = useState(null);
    const [blockSkuList, setBlockSkuList] = useState([]);
    const [ResultArray, setResultArray] = useState([]);
    const [customDeleteProgress, setCustomDeleteProgress] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState("Upload Sheet");
    const [customMessage, setCustomMessage] = useState('Uploading Xcel Sheet...');
    const [isDeleteLoding, setIsDeleteLoding] = useState(false);
    const [customProgress, setCustomProgress] = useState(0);
    const [data, setData] = useState([])
    // const [columns, setColumns] = useState([])
    const navigate = useNavigate();

    const columns = [
        {
            field: 'sr_no',
            title: 'SR NO.'
        },
        {
            field: 'title_of_product',
            title: 'Name',
        },
        {
            field: 'sku',
            title: 'SKU'
        },
        {
            field: 'price',
            title: 'Price'
        },
        {
            field: 'updated_quantity',
            title: 'QUANTITY'
        },
        {
            field: 'updatedAt',
            title: 'Updated At'
        },
    ];




    const handleUploadSheet = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        setCustomProgress(10)
        setLoadingButton("Uploading...")

        const formData = new FormData();
        formData.append('XcelSheet', sheetData);

     let Upload_SheetData = await LocalApi.xcel_sheet_upload(formData).catch((err) => {
            setIsLoading(false)
            setLoadingButton("Upload Sheet")
            setSheetData({})
            // console.log("handleUploadSheet_err", err)
            setCustomProgress(0)
            alert("Something wrong on upload sheet")
            window.location.reload()
        })

    if(Upload_SheetData){
        setCustomProgress(40)
        setCustomMessage("Adding Sheet data")
        var Add_SheetData = await LocalApi.add_price_xcel_sheet_data_db().catch((err) => {
            setIsLoading(false)
            setLoadingButton("Upload Sheet")
            setSheetData({})
            // console.log("handleUploadSheet_err", err)
            setCustomProgress(0)
            alert("Something wrong on Add Sheet Data in db")
            window.location.reload()
        })
    }
        
    if(Add_SheetData){
        setCustomProgress(80)
        setCustomMessage("Check Updated Price.Please wait it takes some time...")
        var Check_Sheet_Data = await LocalApi.check_and_addupdated_price_db().then((res)=>{
            setIsLoading(false)
            setLoadingButton("Upload Sheet")
            setSheetData({})
            setCustomProgress(100)
            alert("Sheet Uploaded successfully")
            window.location.reload()
        }).catch((err) => {
            setIsLoading(false)
            setLoadingButton("Upload Sheet")
            setSheetData({})
            console.log("handleUploadSheet_err", err)
            setCustomProgress(0)
            alert("Something wrong on check sheet data")
            window.location.reload()
        })
    }



    }
    const handleChangeSheet = (event) => {
        setSheetData(event.target.files[0])
    }

    return (
        <>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        {/* <Card> */}
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3 className="card-title">Upload Xcel Sheet for Price Update</h3>
                                    <form encType="multipart/form-data" onSubmit={handleUploadSheet}>
                                        <input
                                            type="file"
                                            name="XcelSheet"
                                            onChange={handleChangeSheet}
                                            className="form-control"
                                            required
                                        />
                                        <MDButton color="light" type="submit" variant="outlined" sx={{ mr: '20px' }} disabled={isloading} >{loadingButton}</MDButton>
                                    </form>
                                </Box>
                            </MDTypography>

                        </MDBox>

                        {isloading ?
                            <>
                                <MDProgress value={customProgress} variant="gradient" label />
                                Please wait while {customMessage}
                            </>
                            :
                            <Table Title="Updated Products" columns={columns} CustomUrl={`product/final_updated_product_pagination?`} />
                        }
                        {/* <MDBox pt={3}>
                                <div className="table-responsive">
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                        }}
                                    >

                                    </Box>
                                </div>
                            </MDBox> */}
                        {/* </Card> */}
                    </Grid>
                </Grid>
            </MDBox>
        </>
    )
}

export default PriceTable