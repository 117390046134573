import React, { useEffect, useMemo, useState, useCallback, useLayoutEffect } from 'react';
// import { DataGrid, gridClasses } from "@mui/x-data-grid";
// import { grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from "../../components/MDButton";
import Table from '../../components/Ui/table.jsx';

// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { Box } from '@mui/material';
import MDProgress from "../../components/MDProgress";
// Import APIs 
import LocalApi from '../../Services/Local/product.local.service';
function Vendor4Page() {
    const [sheetData, setSheetData] = useState({})
    const [pageSize, setPageSize] = useState(5);
    const [rowId, setRowId] = useState(null);
    const [blockSkuList, setBlockSkuList] = useState([]);
    const [ResultArray, setResultArray] = useState([]);
    const [customDeleteProgress, setCustomDeleteProgress] = useState(0);
    const [isloading, setIsLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState("Upload Sheet");
    const [customMessage, setCustomMessage] = useState('Uploading Xcel Sheet...');
    const [isDeleteLoding, setIsDeleteLoding] = useState(false);
    const [customProgress, setCustomProgress] = useState(0);
    const [data, setData] = useState([])
    // const [columns, setColumns] = useState([])
    const navigate = useNavigate();

    const [isCronRun, setIsCronRun] = useState(false);
    const [isCronSyncable, setIsCronSync] = useState(false);

    useLayoutEffect(() => {
        LocalApi.cron_sync_products().then((response) => {
            console.log("cron_sync_products_response", response)
            setIsCronRun(response.data.isFileFatch)
            setIsCronSync(response.data.isFileFatchProcess)
        })
    }, [])


    const columns = [
        {
            field: 'Brand',
            title: 'Brand',
        },
        {
            field: 'Sku',
            title: 'SKU'
        },
        {
            field: 'InventoryItemId',
            title: 'InventoryItemId'
        },
        {
            field: 'Quantity',
            title: 'Quantity'
        }
    ];




    const handleUploadSheet = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        setCustomProgress(10)
        setLoadingButton("Uploading...")

        const formData = new FormData();
        formData.append('csvFile', sheetData);

        let Upload_SheetData = await LocalApi.vendor4_sheet_upload(formData).catch((err) => {
            setIsLoading(false)
            setLoadingButton("Upload Sheet")
            setSheetData({})
            // console.log("handleUploadSheet_err", err)
            setCustomProgress(0)
            alert("Something wrong on upload sheet")
            window.location.reload()
        })

        if (Upload_SheetData) {
            setCustomProgress(50)
            setCustomMessage("Adding Sheet data")
            var Add_SheetData = await LocalApi.update_vendor_4_products().catch((err) => {
                setIsLoading(false)
                setLoadingButton("Upload Sheet")
                setSheetData({})
                // console.log("handleUploadSheet_err", err)
                setCustomProgress(0)
                alert("Something wrong on Add Sheet Data in db")
                window.location.reload()
            }).then((res) => {
                setLoadingButton("Upload Sheet")
                setCustomProgress(100)
                setIsLoading(false)
                alert("Process is started")
                window.location.reload()
            })
        }

    }
    const handleChangeSheet = (event) => {
        setSheetData(event.target.files[0])
    }


    // let Vendor4Uploader = () => {
    //     return (
    //         <MDTypography variant="h6" color="white">
    //             <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
    //                 <h3 className="card-title">Upload Vendor4 Sheet</h3>
    //                 <form encType="multipart/form-data" onSubmit={(e) => handleUploadSheet(e)}>
    //                     <input
    //                         type="file"
    //                         name="csvFile"
    //                         onChange={(e) => handleChangeSheet(e)}
    //                         className="form-control"
    //                         required
    //                     />
    //                     <MDButton color="light" type="submit" variant="outlined" sx={{ mr: '20px' }} disabled={isloading} >{loadingButton}</MDButton>
    //                 </form>
    //             </Box>
    //         </MDTypography>
    //     )
    // }


    return (
        <>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        {/* <Card> */}
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >




                            <MDTypography variant="h6" color="white">
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3 className="card-title">Upload Vendor4 Sheet</h3>

                                    {(isCronSyncable) ?
                                        <>
                                            {(isCronRun) ?
                                                <>
                                                    <div>
                                                        {/* <MDButton className="custom_button_class" sx={{ mr: 2 }} variant="gradient" color="secondary" onClick={() => HandleCancelCron()}>Cancel Cron</MDButton> */}
                                                        <MDButton className="custom_button_class" sx={{ mr: 2 }} disabled variant="gradient" color="light">Process is running...</MDButton>
                                                    </div>
                                                </>
                                                :
                                                <form encType="multipart/form-data" onSubmit={(e) => handleUploadSheet(e)}>
                                                    <input
                                                        type="file"
                                                        name="csvFile"
                                                        onChange={(e) => handleChangeSheet(e)}
                                                        className="form-control"
                                                        required
                                                    />
                                                    <MDButton color="light" type="submit" variant="outlined" sx={{ mr: '20px' }} disabled={isloading} >{loadingButton}</MDButton>
                                                </form>
                                            }
                                        </>
                                        :
                                        <form encType="multipart/form-data" onSubmit={(e) => handleUploadSheet(e)}>
                                            <input
                                                type="file"
                                                name="csvFile"
                                                onChange={(e) => handleChangeSheet(e)}
                                                className="form-control"
                                                required
                                            />
                                            <MDButton color="light" type="submit" variant="outlined" sx={{ mr: '20px' }} disabled={isloading} >{loadingButton}</MDButton>
                                        </form>
                                    }
                                </Box>
                            </MDTypography>


                        </MDBox>

                        {isloading ?
                            <>
                                <MDProgress value={customProgress} variant="gradient" label />
                                Please wait while {customMessage}
                            </>
                            :
                            <Table
                                Title="Updated Vendor4 Products"
                                columns={columns} CustomUrl={`product/final_csv_updated_product_pagination?`}
                                is_btn_show={false}
                                is_edit_items={false}
                            />
                        }
                        {/* <MDBox pt={3}>
                                <div className="table-responsive">
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                        }}
                                    >

                                    </Box>
                                </div>
                            </MDBox> */}
                        {/* </Card> */}
                    </Grid>
                </Grid>
            </MDBox>
        </>
    )
}

export default Vendor4Page