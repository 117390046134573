// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 *  STYLE 5
 */

 ::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     background-color: #F5F5F5;
 }
 
 ::-webkit-scrollbar
 {
     width: 10px;
     background-color: #F5F5F5;
 }
 
 ::-webkit-scrollbar-thumb
 {
     background-color: #3892ee;
     
     background-image: -webkit-gradient(linear, 0 0, 0 100%,
                        color-stop(.5, rgba(255, 255, 255, .2)),
                        color-stop(.5, transparent), to(transparent));
 }
 
 `, "",{"version":3,"sources":["webpack://./src/scrollbar.css"],"names":[],"mappings":"AAAA;;EAEE;;CAED;;KAEI,iDAAiD;KACjD,yBAAyB;CAC7B;;CAEA;;KAEI,WAAW;KACX,yBAAyB;CAC7B;;CAEA;;KAEI,yBAAyB;;KAEzB;;qEAEgE;CACpE","sourcesContent":["/*\n *  STYLE 5\n */\n\n ::-webkit-scrollbar-track\n {\n     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);\n     background-color: #F5F5F5;\n }\n \n ::-webkit-scrollbar\n {\n     width: 10px;\n     background-color: #F5F5F5;\n }\n \n ::-webkit-scrollbar-thumb\n {\n     background-color: #3892ee;\n     \n     background-image: -webkit-gradient(linear, 0 0, 0 100%,\n                        color-stop(.5, rgba(255, 255, 255, .2)),\n                        color-stop(.5, transparent), to(transparent));\n }\n \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
