import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import ItemDetailApi from '../../Services/item.details.services';
import LocalProductApi from '../../Services/Local/product.local.service';
// import c from '../../Services/Local/product.local.service';
import Table from '../../components/Ui/table.jsx';
import { Avatar, Box, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import {
  DataGridPro,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import MDButton from "../../components/MDButton";
import EditIcon from '@mui/icons-material/Edit';
import XMLParser from 'react-xml-parser';
import XML_Data from '../../Data/Inventory1.xml';
import axios from 'axios';
import { xml2js } from 'xml-js';
import ProgressBar from 'react-progressbar';
import { useNavigate } from 'react-router-dom';



// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDProgress from "../../components/MDProgress";

// import 'axios-progress-bar/dist/nprogress.css';
// import NProgress from 'axios-progress-bar';
// import io from 'socket.io-client';
// import 'axios-progress-bar/dist/nprogress.css';
// import { NProgress } from 'axios-progress-bar';

import MaterialTable from 'material-table';

// import globalBaseUrl from '../../Services/';
import globalBaseUrl from '../../Services/baseUrl';
import BaseUrl from 'Services/Local/baseUrl';

let BaseURL = BaseUrl()

const baseUrl = globalBaseUrl()



function SoftwareVersion(props) {
  const [isFileFatch, setIsFileFatch] = useState(false);
  const [isFileFatchProcess, setIsFileFatchProcess] = useState(false);
  const [isSyncProductFromShopify, setIsSyncProductFromShopify] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [resProductArray, setXmlArray] = useState([]);
  const [xmlData, setXmlData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [message, setMessage] = useState('Fatching...');
  const URL = 'softwares';

  const [tableLoadingProgress, setTableLoadingProgress] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [addDataProgress, setAddDataProgress] = useState(0);
  const [filterProgress, setFilterProgress] = useState(0);
  const [checkingProgress, setCheckingProgress] = useState(0);
  const [customProgress, setCustomProgress] = useState(0);
  const [customMessage, setCustomMessage] = useState("Fatching xml file into serve.It's usually takes time...");
  const [fatchedXMlTime, setFatchedXMlTime] = useState('');
  const [fileXMl, setFileXMl] = useState('');
  const [isXMLFile, setIsXMLFile] = useState(false);
  const [isApiCallingProgress, setIsApiCallingProgress] = useState(sessionStorage.getItem("isApiCalling"));
  const [sesstionFilePorcessName, setSessionFileProcessName] = useState(sessionStorage.getItem("FileProcessName"))
  const [sesstionFileProgress_in, setSessionFileProgress_in] = useState(sessionStorage.getItem("FileProgress_in_%"))
  // const [isCronRun, setIsCronRun] = useState(false);
  // sessionStorage.setItem("FileProcessName",'Checking Quantity...')
  // sessionStorage.setItem("FileProgress_in_%",'Adding data...',10)
  const tutorialsRef = useRef();
  tutorialsRef.current = data;
  // console.log("sesstionFilePorcessName", sesstionFilePorcessName)
  // console.log("sesstionFileProgress_in", sesstionFileProgress_in)
  // useLayoutEffect(() => {
  //   sessionStorage.setItem("FileProcessName", "Fatching xml file into serve.It's usually takes time...")
  //   sessionStorage.setItem("FileProgress_in_%", 0)
  // }, [])
  useEffect(() => {
    // if (sesstionFilePorcessName) {
    setSessionFileProcessName(sessionStorage.getItem("FileProcessName"))
    console.log("sesstionFilePorcessName", sesstionFilePorcessName)
    // }
  }, [sessionStorage.getItem("FileProcessName")])

  useEffect(() => {
    // if (sesstionFileProgress_in) {
    setSessionFileProgress_in(sessionStorage.getItem("FileProgress_in_%"))
    console.log("sesstionFileProgress_in", sesstionFileProgress_in)
    // }
  }, [sessionStorage.getItem("FileProgress_in_%")])


  useEffect(() => {
    if (isApiCallingProgress) {
      setIsApiCallingProgress(sessionStorage.getItem("isApiCalling"))
    }
  }, [sessionStorage.getItem("isApiCalling")])


  useEffect(() => {
    if (isApiCallingProgress) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = ''; // This line is needed for Chrome compatibility
        // You can customize the message displayed to the user in the prompt below

        return 'Are you sure you want to leave this page? Your unsaved changes will be lost.';
      };

      // Add the event listener when the component mounts
      window.addEventListener('beforeunload', handleBeforeUnload);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }

  }, [isApiCallingProgress]);

  console.log("isApiCallingProgress", isApiCallingProgress)

  const handleDateTime = (DateTime) => {
    const datetime = new Date(DateTime);

    const formatDate = datetime.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const formatTime = datetime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    return `${formatDate} - ${formatTime}`
  }

  const handleDateTime_XML = (DateTime) => {
    const datetime = new Date(DateTime);

    const formatDate = datetime.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    const formatTime = datetime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    return `${formatDate} - ${formatTime}`
  }
  useEffect(() => {
    LocalProductApi.get_last_fatched_xml().then((response) => {
      console.log("get_last_fatched_xml_res", response)
      const Last_Fatched_Time = handleDateTime_XML(response.data.xml_file_list.time);
      setFatchedXMlTime(Last_Fatched_Time)
    }).catch((error) => {
      console.log("get_last_fatched_xml_error", error)
    })
  }, [])

  useLayoutEffect(() => {
    LocalProductApi.cron_sync_products().then((response) => {
      // console.log("cron_sync_products_response", response)
      setIsSyncProductFromShopify(response?.data?.isSyncProductFromShopify)
      setIsFileFatch(response.data.isFileFatch)
      setIsFileFatchProcess(response.data.isFileFatchProcess)
    })
  }, [])


  let FinalArray = [];
  productsData?.map((item, i) => {

    let obj = {
      _id: item._id,
      product_id: item.product_id,
      sr_no: item.sr_no,
      product_title: item.product_title,
      sku: item.sku,
      price: item.price,
      id: item.id,
      updated_quantity: item.updated_quantity ? item.updated_quantity : item.inventory_quantity,
      updatedAt: item.updatedAt != item.createdAt ? handleDateTime(item.updatedAt) : '-',
      status: item.updatedAt != item.createdAt ? 'Updated' : '',
    };
    FinalArray.push(obj)
  })



  const XmlFileUpload = loading ? `${message}` : 'Fatch Xml'
  // const CheckUpdate = loadingCheck ? 'Checking...' : 'Check for Update'

  let CustomMessage = `
    Please wait For File Fatching.It's usually takes an hour.
    ${XmlFileUpload} is currently running...
    `



  const handleFatchXML = async (event) => {
    setLoading(true)
    setIsApiCallingProgress(true)
    sessionStorage.setItem("isApiCalling", true);
    let stop_Analysis_FileFating_objData = {
      isFileFatchProcess: true
    }
    await LocalProductApi.stop_Analysis_FileFating_Update(stop_Analysis_FileFating_objData).catch((err) => {
      console.log("stop_Analysis_FileFating", err)
    })


    setIsXMLFile(true)
    const FileName = await LocalProductApi.fetch_xml_date_time()

    if (FileName) {
      const File_Time = handleDateTime(FileName.data.Date)
      setFileXMl(File_Time)
      setCustomProgress(10)
      sessionStorage.setItem("FileProgress_in_%", 10)
    }

    const file = await axios.get(`${baseUrl}/xml/fetch_xml`, {
      onDownloadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setCustomProgress(progress)
      },
    }).catch(error => {
      alert("Try again later on FileUpload");
      setLoading(false)
      setIsApiCallingProgress(false)
      sessionStorage.setItem("isApiCalling", false);
      window.location.reload();
      setUploadProgress(0);
    });


    setCustomProgress(35)
    sessionStorage.setItem("FileProgress_in_%", 35)
    // Adding Data Api Call
    if (file) {
      // setCustomProgress(30)
      let FileProcessNameObj = {

      }
      setMessage('Adding data...')
      sessionStorage.setItem("FileProcessName", 'Adding data...')

      setCustomMessage('Adding XML data into server.It usually takes 2 minutes...')
      var addData = await axios.get(`${baseUrl}/xml/add_xml`).catch((error) => {
        alert("Try again later on FileUpload");
        setLoading(false)
        setIsApiCallingProgress(false)
        sessionStorage.setItem("isApiCalling", false);
        window.location.reload();
        setAddDataProgress(0)
      })
    }



    // Filter Data Api Call
    if (addData) {
      setCustomProgress(70)
      sessionStorage.setItem("FileProgress_in_%", 70)
      setMessage('Filter data...')
      sessionStorage.setItem("FileProcessName", 'Filter data...')
      setCustomMessage('Filter Xml data...')
      var filterData = await axios.post(`${baseUrl}/xml/filter_xml`)
    }


    // Checking Quantity Api Call
    if (filterData) {
      setCustomProgress(80)
      sessionStorage.setItem("FileProgress_in_%", 80)
      setMessage('Checking Quantity...')
      sessionStorage.setItem("FileProcessName", 'Checking Quantity...')
      setCustomMessage('Checking Updated Quantity...')
      var check_quantity_by_sku_db = await axios.get(`${baseUrl}/product/check_quantity_by_sku_db`).catch((err) => {
        alert("Try again later on Quantity checked");
        setCustomProgress(0)
        setLoadingCheck(false)
        setIsApiCallingProgress(false)
        sessionStorage.setItem("isApiCalling", false);
        window.location.reload();
      })
    }


    if (check_quantity_by_sku_db) {
      setCustomProgress(90)
      sessionStorage.setItem("FileProgress_in_%", 90)
      await axios.post(`${baseUrl}/product/add_updated_product_db`).then(async (res) => {
        setCustomProgress(100)
        sessionStorage.setItem("FileProgress_in_%", 100)
        let objData = {
          isFileFatchProcess: false,
          isFileFatch: false
        }
        await LocalProductApi.stop_Analysis_FileFating_Update(objData).catch((err) => {
          console.log("stop_Analysis_FileFating", err)
        })
        setIsApiCallingProgress(false)
        sessionStorage.setItem("isApiCalling", false)
        alert("File uploaded successfully!");
        setLoadingCheck(false)
        setIsXMLFile(false)
        window.location.reload();
      }).catch((err) => {
        alert("Try again later on Quantity checked");
        setCustomProgress(0)
        setLoadingCheck(false)
        setIsApiCallingProgress(false)
        sessionStorage.setItem("isApiCalling", false);
        window.location.reload();
      })

      setCustomProgress(0)
      setLoading(false)
      setIsApiCallingProgress(false)
      sessionStorage.setItem("isApiCalling", false);
      window.location.reload();
    }

  };

  // let navigate = useNavigate();
  // let location = useLocation()
  // console.log("location", location)
  const HandleCancelFatching = async (event) => {
    event.preventDefault();
    let objData = {
      isFileFatchProcess: false,
      isFileFatch: false
    }
    await LocalProductApi.stop_Analysis_FileFating_Update(objData).then(() => {
      setIsApiCallingProgress(false)
      sessionStorage.setItem("isApiCalling", false)
      window.location.reload()
    }).catch((err) => {
      console.log("stop_Analysis_FileFating", err)
    })
  }


  const columns = [
    {
      field: 'sr_no',
      title: 'SR NO.'
    },
    {
      field: 'product_title',
      title: 'Name',
    },
    {
      field: 'sku',
      title: 'SKU'
    },
    {
      field: 'price',
      title: 'Price'
    },
    {
      field: 'updated_quantity',
      title: 'QUANTITY'
    },
    {
      field: 'updatedAt',
      title: 'Updated At'
    },
  ];
  return (
    <>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3 className="card-title">XML File</h3>
                  <form encType="multipart/form-data">
                    {/* <input
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      type="file"
                      style={{ display: "none" }}
                    // multiple={false}
                    /> */}
                    {/* setMessage('Checking Quantity...') */}
                    {/* message */}

                    {((!isFileFatch && isFileFatchProcess) || (isFileFatch && !isFileFatchProcess) || (isFileFatch && isFileFatchProcess) || isSyncProductFromShopify) ?
                      <>
                        {isSyncProductFromShopify ?
                          <>
                            <MDButton
                              className="custom_button_class"
                              color="secondary"
                              variant="gradient"
                              type="submit"
                              // sx={{ mr: '-490px' }}
                              disabled={isSyncProductFromShopify}
                            >Syncing Products Process is running...
                            </MDButton>
                          </> :
                          <>
                            <>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                  {
                                    (isXMLFile) ?
                                      <>
                                        {fileXMl ? <h5 className="card-title" sx={{ mr: 2 }}>Now Fatching filetime... {fileXMl}</h5> : <></>}
                                      </>
                                      :
                                      <>
                                        {fatchedXMlTime ? <h5 className="card-title" sx={{ mr: 2 }}>Last fatched filetime {fatchedXMlTime}</h5> : <></>}
                                      </>
                                  }
                                </div>
                                <div>
                                  {loading ?
                                    <>
                                      {(message !== 'Checking Quantity...' || message !== 'Uploading...') ? <MDButton sx={{ mx: 2 }} variant="gradient" color="secondary" disabled>Please wait, File is fatching...</MDButton>
                                        :
                                        <>
                                          <MDButton className="custom_button_class" sx={{ mx: 2 }} variant="gradient" color="secondary" onClick={HandleCancelFatching} >Cancel Fetching</MDButton>
                                          <MDButton className="custom_button_class" sx={{ mx: 2 }} variant="gradient" color="light" disabled >Please wait, File is fatching...</MDButton>
                                        </>
                                      }
                                    </>
                                    :
                                    <>
                                      <MDButton className="custom_button_class" sx={{ mx: 2 }} variant="gradient" color="secondary" onClick={HandleCancelFatching} >Cancel Fetching</MDButton>
                                      <MDButton className="custom_button_class" sx={{ mx: 2 }} variant="gradient" color="light" disabled >Please wait, File is fatching...</MDButton>
                                    </>
                                  }
                                </div>
                              </Box>
                            </>
                          </>
                        }
                      </>
                      :
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                          <div>
                            {
                              (isXMLFile) ?
                                <>
                                  {fileXMl ? <h5 className="card-title" sx={{ mr: 2 }}>Now Fatching filetime... {fileXMl}</h5> : <></>}
                                </>
                                :
                                <>
                                  {fatchedXMlTime ? <h5 className="card-title" sx={{ mr: 2 }}>Last fatched filetime {fatchedXMlTime}</h5> : <></>}
                                </>
                            }
                          </div>
                          <div>
                            {loading ?
                              <>
                                {(message !== 'Checking Quantity...' || message !== 'Uploading...') ? <MDButton sx={{ mx: 2 }} variant="gradient" color="secondary" onClick={() => window.location.reload()} >{XmlFileUpload}</MDButton>
                                  :
                                  <MDButton sx={{ mx: 2 }} variant="gradient" color="secondary" >{XmlFileUpload}</MDButton>
                                }
                              </>
                              :
                              <MDButton className="custom_button_class" sx={{ mx: 2 }} variant="gradient" color="secondary" onClick={() => handleFatchXML()}>{XmlFileUpload}</MDButton>
                            }
                          </div>
                        </Box>
                      </>
                    }

                  </form>
                </Box>
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      {(loading || loadingCheck || customProgress) ?
        <>
          <MDProgress value={customProgress} variant="gradient" label />
          {/* <ProgressBar completed={customProgress} /> */}
          {/* `customProgress: ${customProgress}`
           `Uploading: ${uploadProgress}` loading Please wait...
           `addDataProgress: ${addDataProgress}`
           `filterProgress: ${filterProgress}`
           `checkingProgress: ${checkingProgress}` */}
          Please wait while {customMessage}
        </>
        :
        <>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
              {tableLoading ?
                <>
                  <MDProgress value={tableLoadingProgress} variant="gradient" label />
                  Please wait while the table data is loading...
                </>
                :
                <>
                  {/* isSyncProductFromShopify */}
                  {((!isFileFatch && isFileFatchProcess) || (isFileFatch && !isFileFatchProcess) || (isFileFatch && isFileFatchProcess) || isSyncProductFromShopify) ?
                    <>
                      {isSyncProductFromShopify ?
                        <>
                          Please wait while Syncing Products Process is running...
                        </>
                        :
                        <>
                          <>
                            {sesstionFileProgress_in && sesstionFilePorcessName ?
                              <>
                                <MDProgress value={sesstionFileProgress_in} variant="gradient" label />
                                Please wait while {sesstionFilePorcessName}
                              </>
                              :
                              <>
                                {CustomMessage}
                              </>
                            }
                            {/* {CustomMessage} == */}

                          </>
                        </>}
                    </>
                    :
                    <>
                      {!tableLoading && <Table Title="Products" columns={columns} CustomUrl={`product/final_product_table_pagination?`} />}
                    </>}

                </>
              }
            </div>
          </div>
        </>
      }
    </>
  )
}

export default SoftwareVersion;