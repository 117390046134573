function BaseUrl() {
  // if (process.env.REACT_APP_NEXT_PUBLIC_REST_API_ENDPOINT) return process.env.REACT_APP_NEXT_PUBLIC_REST_API_ENDPOINT
  return "http://185.199.53.181/api";
  // return "http://localhost:3001/api";
// 

}

export default BaseUrl


