import React, { useEffect, useMemo, useState, useCallback, useLayoutEffect } from 'react';
import LocalProductApi from '../../Services/Local/product.local.service';
import { Avatar, Box, Typography } from '@mui/material';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from "../../components/MDButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MaterialTable from 'material-table';

// Import APIs 
// import ProductApi from '../../Services/item.details.services';
// import globalBaseUrl from '../../Services/baseUrl';
import BaseUrl from 'Services/Local/baseUrl';

let BaseURL = BaseUrl()
function Table({ Title, columns, data, CustomUrl, is_btn_show = true, is_edit_items = true }) {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCronRun, setIsCronRun] = useState(false);
  const [isCronSyncable, setIsCronSync] = useState(false);

  const [isSyncCall, setIsSyncCall] = useState(false);
  const handleRowEditCommit = useCallback(
    // (params) => {
    //   const id = params.id;
    //   const key = params.field;
    //   const value = params.value;
    // },
    (params) => {
      // const Update_data = ProductApi.editSoftwareVersions(params.id, params.name)
      setRowId(params.id)
      console.log("params", params)
    },
    []
  )
  useLayoutEffect(() => {
    LocalProductApi.cron_sync_products().then((response) => {
      console.log("cron_sync_products_response", response)
      // setIsCronRun(response.data.isFileFatch)
      // setIsCronSync(response.data.isFileFatchProcess)
      setIsCronRun(response.data.isCronrun)
      setIsCronSync(response.data.isCronSyncable)
    })
  }, [])
  // const HandleSyncData = async (event) => {
  //   event.preventDefault();
  //   setLoading(true)
  //   await LocalProductApi.syncData().then((res) => {
  //     alert("Data Synced");
  //     setLoading(false)
  //     window.location.reload();
  //   }).catch((err) => {
  //     alert("Try again later");
  //     setLoading(false)
  //     window.location.reload();
  //   })
  // }


  const HandleXmlUpdateCheck = async (event) => {

    setIsSyncCall(true)
    await LocalProductApi.syncData().then((res) => {
      alert("Cron job started successfully. Now wait for couple of hours to sync all products to shopify");
      setIsSyncCall(false)
      window.location.reload();
    }).catch((err) => {
      alert("Try again later");
      setIsSyncCall(false)
      window.location.reload();
    })
  }

  const HandleCancelCron = async () => {
    await LocalProductApi.stop_update_products_to_shopify().then(() => {
      alert("Cron job successfully stoped");
      window.location.reload();
    }).catch((err) => {
      alert("Try again later");
      window.location.reload();
    })
  }



  const navigate = useNavigate();

  const _navigateToPage = (props) => {

    if (CustomUrl == 'product/final_updated_product_pagination?') {
      var Name = props.title_of_product
      var Price = props.price ? props.price : props.old_price
    } else {
      var Name = props.product_title
      var Price = props.price
    }
    console.log("setProductsData", props)
    const SKU = props.sku
    const QUANTITY = props.updated_quantity ? props.updated_quantity : props.inventory_quantity
    const product_id = props.product_id
    const Varient_id = props.id
    const TAGS = props.tags
    navigate(`/products/edit/${props._id}`, {
      state: {
        Name,
        Price,
        SKU,
        QUANTITY,
        product_id,
        Varient_id,
        TAGS
      },
    });
  }



  return (
    <>
      {loading ?
        <>
          Please Wait while Data is Sync with Shopify...
        </>
        :
        <>
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 className="card-title">{Title}</h3>
                        {/* <MDButton variant="gradient" color="secondary" oncClick={(event) => HandleSyncData(event)}>Sync Data</MDButton> */}
                        {/* isCronRun || isCronSyncable */}
                        {is_btn_show ? (isCronSyncable) ?
                          <>
                            {(isCronRun) ?
                              <>
                                <div>
                                  <MDButton className="custom_button_class" sx={{ mr: 2 }} variant="gradient" color="secondary" onClick={() => HandleCancelCron()}>Cancel Cron</MDButton>
                                  <MDButton className="custom_button_class" sx={{ mr: 2 }} disabled variant="gradient" color="light">Cron job is running wait for couple of hours</MDButton>
                                </div>
                              </>
                              :
                              <>
                                <MDButton className="custom_button_class_sync" variant="gradient" color="light" onClick={() => HandleXmlUpdateCheck()}>Sync All Data</MDButton>
                              </>
                            }
                          </>
                          :
                          <>
                            <MDButton className="custom_button_class_sync" variant="gradient" color="light" onClick={() => HandleXmlUpdateCheck()}>Sync All Data</MDButton>
                          </> : null
                        }
                        {/* {(isCronSyncable) &&
                          <>
                            {(isCronRun) &&
                              <>
                                <div>
                                  <MDButton className="custom_button_class" sx={{ mr: 2 }} variant="gradient" color="secondary" onClick={() => HandleCancelCron()}>Cancel Cron</MDButton>
                                  <MDButton className="custom_button_class" sx={{ mr: 2 }} disabled variant="gradient" color="light">Vendor4 Process is running...</MDButton>
                                </div>
                              </>}
                          </>} */}
                          
                        {/* <>
                          
                          <div>
                          <MDButton sx={{mr:2}} variant="gradient" color="light" onClick={() => HandleCancelCron()}>Cancel Cron</MDButton>
                          <MDButton disabled variant="gradient" color="light">Cron job is running wait for couple of hours</MDButton>
                          </div>
                          </> */}



                        {/* <Link to={`/item-detail/${url}/add`} type="button" className="btn btn-outline-success btn-fw mb-4">Add {Title}</Link> */}
                      </Box>
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <div className="table-responsive">
                      <Box
                        sx={{
                          height: "100%",
                          width: '100%',
                          borderRadius: "20px"
                        }}
                      >
                        <MaterialTable
                          title="Products"
                          columns={columns}
                          // options={{ debounceInterval: 800, padding: "dense" }}
                          data={query =>
                            new Promise((resolve, reject) => {
                              // prepare your data and then call resolve like this:
                              let url = `${BaseURL}/${CustomUrl}`

                              //searching
                              if (query.search) {
                                url += `search=${query.search}`
                              }
                              //sorting 
                              if (query.orderBy) {
                                url += `&_sort=${query.orderBy.field}&_order=${query.orderDirection}`
                              }
                              //filtering
                              if (query.filters.length) {
                                const filter = query.filters.map(filter => {
                                  return `&${filter.column.field}${filter.operator}${filter.value}`
                                })
                                url += filter.join('')
                              }
                              //pagination
                              url += `&_page=${query.page}`
                              url += `&_limit=${query.pageSize}`

                              fetch(url).then(resp => resp.json()).then(resp => {
                                // console.log("resp", resp)

                                let Final_Data_Arr = []
                                for (let i = 0; i < resp?.ProductData?.length; i++) {
                                  const dateObject = new Date(resp?.ProductData[i].updatedAt);
                                  const formattedDate = dateObject.toLocaleString('en-US', {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric',
                                    hour12: true,
                                  });
                                  let obj = {
                                    ...resp?.ProductData[i],
                                    updatedAt: formattedDate
                                  }
                                  Final_Data_Arr.push(obj)
                                }
                                resolve({
                                  data: Final_Data_Arr,// your data array
                                  page: query.page,// current page number
                                  totalCount: resp?.totalCount// total row number
                                });
                              })

                            })
                          }
                          actions={is_edit_items ? [
                            {
                              icon: 'edit',
                              tooltip: 'Save User',
                              onClick: (event, rowData) => _navigateToPage(rowData)
                            },

                          ] : []}
                        />
                      </Box>
                    </div>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      }
    </>
  )
}

export default Table