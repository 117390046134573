import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import PrivateRoutes from './Routes/PrivateRoutes';
import Vendor4Page from 'Pages/Vendor4';


const Users = lazy(() => import('./Pages/User/index.jsx'));
const CreateUser = lazy(() => import('./Pages/User/Create.jsx'));
const EditUser = lazy(() => import('./Pages/User/[userSlug]/edit.jsx'));

const Products = lazy(() => import('./Pages/Products/index.jsx'));
const EditProduct = lazy(() => import('./Pages/Products/[Slug]/edit.jsx'));

const BlockSku = lazy(() => import('./Pages/BlockSKU/index.jsx'));
const AddBlockSku = lazy(() => import('./Pages/BlockSKU/create.jsx'));
const EditBlockSku = lazy(() => import('./Pages/BlockSKU/[Slug]/edit.jsx'));

const XMLFile = lazy(() => import('./Pages/XMLFiles/index.jsx'))
const SyncProduct = lazy(() => import('./Pages/Sync_Products/index.jsx'))
const MultiTagsProducts = lazy(() => import('./Pages/Multiple_Tags/create.jsx'))

const PriceList = lazy(() => import('./Pages/Price/index.jsx'))
// End of Import of item detail router

function AppRoutes() {
    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }

            return null;
        });

    return (
        <>
            <Suspense fallback={<div>Loading... </div>}>
                <Routes>
                    {getRoutes(PrivateRoutes)}

                    {/* Users Router */}
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/adduser" element={<CreateUser />} />
                    <Route path="/users/edituser/:id" element={<EditUser />} />

                    {/* Prodduct Router */}
                    <Route path="/products" element={<Products />} />
                    <Route path="/products/edit/:id" element={<EditProduct />} />

                    {/* BlockSku Router */}
                    <Route path="/block-sku" element={<BlockSku />} />
                    <Route path="/block-sku/add" element={<AddBlockSku />} />
                    <Route path="/block-sku/edit/:id" element={<EditBlockSku />} />

                    {/* PriceList */}
                    <Route path="/price-list" element={<PriceList />} />

                    {/* Vendor 4 products page */}
                    <Route path="/vendor4-updated-products" element={<Vendor4Page />} />


                    {/* Sync Products */}
                    <Route path="/sync-products" element={<SyncProduct />} />

                    {/* MultiTagsProducts */}
                    <Route path="/add-multi-tags-products" element={<MultiTagsProducts />} />

                    {/* XMLFiles Router */}
                    <Route path="/xml-files" element={<XMLFile />} />

                    {/* All Item Details Router End */}

                    {/* Page not Found */}
                    <Route path="*" element={<Navigate to="/products" />} />
                </Routes>
            </Suspense>
        </>
    );

}

export default AppRoutes;