import Dashboard from "layouts/dashboard";
import Products from './Pages/Products/index.jsx'
import BlockSKu from './Pages/BlockSKU/index.jsx'
import XMLFile from './Pages/XMLFiles/index.jsx'
import PriceList from './Pages/Price/index.jsx';
import SyncProduct from './Pages/Sync_Products/index.jsx'
import MultiTagsProducts from './Pages/Multiple_Tags/create.jsx'

// @mui icons
import Icon from "@mui/material/Icon";
import CategoryIcon from '@mui/icons-material/Category';
import BlockIcon from '@mui/icons-material/Block';
import TaskIcon from '@mui/icons-material/Task';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SyncIcon from '@mui/icons-material/Sync';
import StyleIcon from '@mui/icons-material/Style';
import Vendor4Page from "Pages/Vendor4/index.jsx";
const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  {
    type: "collapse",
    name: "Inventory",
    key: "products",
    icon: <CategoryIcon />,
    route: `/products`,
    component: <Products />,
  },
  {
    type: "collapse",
    name: "Blocked-SKUs",
    key: "block-sku",
    icon: <BlockIcon />,
    route: `/block-sku`,
    component: <BlockSKu />,
  },
  {
    type: "collapse",
    name: "Fatched XML",
    key: "xml-files",
    icon: <TaskIcon />,
    route: `/xml-files`,
    component: <XMLFile />,
  },
  {
    type: "collapse",
    name: "Updated Price",
    key: "price-list",
    icon: <LocalOfferIcon />,
    route: `/price-list`,
    component: <PriceList />,
  },
  {
    type: "collapse",
    name: "Sync Products",
    key: "sync-products",
    icon: <SyncIcon />,
    route: `/sync-products`,
    component: <SyncProduct />,
  },
  {
    type: "collapse",
    name: "Add Tags on Products",
    key: "add-multi-tags-products",
    icon: <StyleIcon />,
    route: `/add-multi-tags-products`,
    component: <MultiTagsProducts />,
  },
  {
    type: "collapse",
    name: "Vendor4 Products",
    key: "vendor4-updated-products",
    icon: <TaskIcon />,
    route: `/vendor4-updated-products`,
    component: <Vendor4Page />,
  },
  
];

export default routes;
