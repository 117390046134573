import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from "../../components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MDProgress from "../../components/MDProgress";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { Box } from '@mui/material';

// Import APIs 
import LocalApi from '../../Services/Local/product.local.service';



function Index() {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [blockSkuList, setBlockSkuList] = useState([]);
  const [ResultArray, setResultArray] = useState([]);
  const [customDeleteProgress, setCustomDeleteProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDeleteLoding, setIsDeleteLoding] = useState(false);
  const [data, setData] = useState([])
  // const [columns, setColumns] = useState([])
  const navigate = useNavigate();



  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted or not

    LocalApi.get_all_block_sku().then((response) => {
      if (isMounted) {
        setBlockSkuList(response.data.sku_list)
        let resultArray = [];
        for (let i = 0; i < response.data.sku_list.length; i++) {
          let obj = {
            _id: response.data.sku_list[i]._id,
            sr_no: i + 1,
            sku: response.data.sku_list[i].sku
          }
          resultArray.push(obj);
        }
        setResultArray(resultArray);
      }
    }).catch((error) => {
      console.log("get_all_block_sku_api_err", error)
    })
    return () => {
      isMounted = false; // Mark the component as unmounted
    };
  }, [])

  // let resultArray = [];
  // for (let i = 0; i < blockSkuList.length; i++) {
  //   let obj = {
  //     _id: blockSkuList[i]._id,
  //     sr_no: i + 1,
  //     sku: blockSkuList[i].sku
  //   }
  //   resultArray.push(obj)
  // }
  // setResultArray(resultArray)
  const handleRowEditCommit = useCallback(
    (params) => {
      setRowId(params.id)
      console.log("params", params)
    },
    []
  )

  const _navigateToPage = (props) => {
    console.log("setProductsData", props.row)
    const SKU = props.row.sku
    const id = props.row._id
    navigate(`/block-sku/edit/${id}`, {
      state: {
        SKU,
      },
    });

  }

  const handleDelete = async (props) => {
    const SKU = props.row.sku
    const id = props.row._id
    // const index = resultArray.indexOf(SKU);
    // console.log("index",index)
    setIsDeleteLoding(true)
    await LocalApi.delete_block_sku(id).then((response) => {
      console.log("delete_block_sku_response", response)
      const filteredArray = ResultArray.filter(obj => obj.sku !== SKU);
      setResultArray(filteredArray)
      setIsDeleteLoding(false)
      setCustomDeleteProgress(100)
    }).catch((error) => {
      alert('try again later')
      setIsDeleteLoding(false)
    })
    setCustomDeleteProgress(0)
  }
// const result  = confirm('Are you sure you want to delete all SKUs?')
  const handleMultipalDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm('Are you sure you want to delete this item?');
    if(result){
      setCustomDeleteProgress(0)
      setIsDeleteLoding(true)
        await LocalApi.delete_multiple_block_sku().then((response) => {
          setCustomDeleteProgress(100)
          alert('all blocked SKUs deleted')
          setIsDeleteLoding(false)
          window.location.reload()
        }).catch((error) => {
          alert('try again later')
          setCustomDeleteProgress(0)
          setIsDeleteLoding(false)
          window.location.reload()
        })
        setCustomDeleteProgress(0)
    }
  }




  const columns = [
    {
      field: 'sr_no',
      headerName: 'SR NO.',
      width: 290,
    },
    {
      field: 'sku',
      headerName: '	SKU',
      width: 450,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      getActions: (props) => [
        <MDButton variant="outlined" color="info" size="medium" onClick={() => _navigateToPage(props)} sx={{ mr: 1 }} >
          <GridActionsCellItem icon={<EditIcon />} label="Edit" />
        </MDButton>,
        <MDButton variant="outlined" size="medium" color="error" onClick={() => handleDelete(props)} >
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" />
        </MDButton>
      ]
    },
  ];







  return (
    <>
      <>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3 className="card-title">SKU List</h3>
                      <div>
                      <MDButton variant="gradient" color="light" onClick={() => navigate('/block-sku/add')} sx={{ mr: '20px' }}>Add SKU</MDButton>
                      <MDButton variant="outlined" color="light" onClick={() => handleMultipalDelete()}>Delete All SKU</MDButton>
                      </div>
                     
                    </Box>
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <div className="table-responsive">
                    <Box
                      sx={{
                        height: 500,
                        width: '100%',
                      }}
                    >
                      {isDeleteLoding ?
                        <>
                          <MDProgress value={customDeleteProgress} variant="gradient" label />
                          Please Wait while Sku is deleting...
                        </>
                        : <>
                          <DataGrid
                            experimentalFeatures={{ newEditingApi: true }}
                            // checkboxSelection
                            columns={columns}
                            rows={ResultArray}
                            getRowId={(row) => row._id}
                            rowsPerPageOptions={[5, 10, 20]}
                            pageSize={pageSize}
                            onCellEditCommit={handleRowEditCommit}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowSpacing={(params) => ({
                              top: params.isFirstVisible ? 0 : 5,
                              bottom: params.isLastVisible ? 0 : 5,
                            })}
                            sx={{
                              [`& .${gridClasses.row}`]: {
                                bgcolor: (theme) =>
                                  theme.palette.mode === 'light' ? grey[200] : grey[900],
                              },
                            }}
                          // onCellEditCommit={(params) => setRowId(params.id)}
                          />
                        </>
                      }
                    </Box>
                  </div>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </>
    </>
  )
}

export default Index