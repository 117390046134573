import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import MDButton from "../../components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MDProgress from "../../components/MDProgress";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import { Box } from '@mui/material';

// Import APIs 
import LocalApi from '../../Services/Local/product.local.service';



function Index() {
  const [pageSize, setPageSize] = useState(5);
  const [rowId, setRowId] = useState(null);
  const [xmlFileList, setXMLFileList] = useState([]);
  const [ResultArray, setResultArray] = useState([]);
  const [customProgress, setCustomProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDeleteLoding, setIsDeleteLoding] = useState(false);
  const [data, setData] = useState([])
  // const [columns, setColumns] = useState([])
  const navigate = useNavigate();



  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted or not
    setLoading(true)
    LocalApi.get_all_fatched_xml().then((response) => {
      if (isMounted) {

        setXMLFileList(response.data.xml_file_list)
        let resultArray = [];
        let counter = 0;
        const XML_ARR = response.data.xml_file_list
        for (let i = 0; i < XML_ARR.length; i++) {
          const datetime = new Date(XML_ARR[i].time)
          const XMLDate = datetime.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
          const XMLTime = datetime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          })

          const Last_datetime = new Date(XML_ARR[i].createdAt)
          const Last_XMLDate = Last_datetime.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
          const Last_XMLTime = Last_datetime.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
          })


          let obj = {
            _id: XML_ARR[i]._id,
            sr_no: counter + 1,
            name: XML_ARR[i].name,
            date_time: `${XMLDate} - ${XMLTime}`,
            LastFatched: `${Last_XMLDate} - ${Last_XMLTime}`
          }
          resultArray.push(obj);
          counter++;
        }
        setResultArray(resultArray);
        console.log("get_all_fatched_xml_response", resultArray)
        setLoading(false);
      }
    }).catch((error) => {
      console.log("get_all_block_sku_api_err", error)
      setLoading(false);
    })
    return () => {
      isMounted = false; // Mark the component as unmounted
      setLoading(false);
    };
  }, [])


  // const getDate = (DateTime) => {
  //   const datetime = new Date(DateTime)

  //   const Date = datetime.toLocaleDateString('en-US', {
  //     year: 'numeric',
  //     month: 'numeric',
  //     day: 'numeric',
  //   })

  //   return Date
  // }

  // const getTime = (DateTime) => {
  //   const datetime = new Date(DateTime)


  //   const Time = datetime.toLocaleDateString('en-US', {
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     second: 'numeric',
  //     hour12: true
  //   })

  //   return Time

  // }


  // let resultArray = [];
  // // const XML_ARR = xmlFileList
  // for (let i = 0; i < xmlFileList.length; i++) {
  //   let obj = {
  //     _id: xmlFileList[i]._id,
  //     sr_no: i + 1,
  //     name:xmlFileList[i].name,
  //     date: getDate(xmlFileList[i].time),
  //     time: getTime(xmlFileList[i].time)
  //   }
  //   resultArray.push(obj)
  // }
  // setResultArray(resultArray)

  const handleRowEditCommit = useCallback(
    (params) => {
      setRowId(params.id)
      console.log("params", params)
    },
    []
  )



  const columns = [
    {
      field: 'sr_no',
      headerName: 'SR NO.',
      width: 150,
    },
    {
      field: 'name',
      headerName: 'Fatched File',
      width: 200,
    },
    {
      field: 'date_time',
      headerName: 'File DateTime',
      width: 350,
    },
    {
      field: 'LastFatched',
      headerName: 'LastFatched',
      width: 250,
    },
  ];




  return (
    <>
      <>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3 className="card-title">Fatched XML List</h3>
                      <div>
                        {/* <MDButton variant="gradient" color="light" onClick={() => navigate('/block-sku/add')} sx={{ mr: '20px' }}>Add SKU</MDButton> */}
                        {/* <MDButton variant="outlined" color="light" onClick={() => handleMultipalDelete()}>Delete All SKU</MDButton> */}
                      </div>

                    </Box>
                  </MDTypography>
                </MDBox>
                {loading ?
                  <>
                    <MDProgress value={customProgress} variant="gradient" label />
                    Please Wait while XML Files data is loading...
                  </>
                  :
                  <>
                    <MDBox pt={3}>
                      <div className="table-responsive">
                        <Box
                          sx={{
                            height: 500,
                            width: '100%',
                          }}
                        >
                          <DataGrid
                            experimentalFeatures={{ newEditingApi: true }}
                            // checkboxSelection
                            columns={columns}
                            rows={ResultArray}
                            getRowId={(row) => row._id}
                            rowsPerPageOptions={[5, 10, 20]}
                            pageSize={pageSize}
                            onCellEditCommit={handleRowEditCommit}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            getRowSpacing={(params) => ({
                              top: params.isFirstVisible ? 0 : 5,
                              bottom: params.isLastVisible ? 0 : 5,
                            })}
                            sx={{
                              [`& .${gridClasses.row}`]: {
                                bgcolor: (theme) =>
                                  theme.palette.mode === 'light' ? grey[200] : grey[900],
                              },
                            }}
                          // onCellEditCommit={(params) => setRowId(params.id)}
                          />
                        </Box>
                      </div>
                    </MDBox>
                  </>
                }
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </>
    </>
  )
}

export default Index