// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom_button_class {
  padding: 10px !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
  font-size: 12px !important;
}

.custom_button_class_sync {
  color: rgb(70, 70, 70) !important;
  padding: 10px !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
  font-size: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,0BAA0B;AAC5B;;AAEA;EACE;aACW;AACb;;;AAGA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,4BAA4B;EAC5B,0BAA0B;AAC5B;;AAEA;EACE,iCAAiC;EACjC,wBAAwB;EACxB,8BAA8B;EAC9B,4BAA4B;EAC5B,0BAA0B;AAC5B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 15px !important;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n.custom_button_class {\n  padding: 10px !important;\n  border-radius: 10px !important;\n  margin-left: 10px !important;\n  font-size: 12px !important;\n}\n\n.custom_button_class_sync {\n  color: rgb(70, 70, 70) !important;\n  padding: 10px !important;\n  border-radius: 10px !important;\n  margin-left: 10px !important;\n  font-size: 12px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
